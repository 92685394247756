<template>
  <div class="accordion-volumes">
    <p class="h6 font-weight-bold text-dark-darken">
      {{ $t('orderEditModals.infoDetails.volumeNumber') }}:
      <b class="text-danger">{{ data[0].volume_number }}</b>
    </p>

    <hr />
    <div
      class="d-flex flex-column mb-3 valume-item"
      v-for="item in data"
      :key="`info-row-${item.id}`"
    >
      <div class="d-flex flex-column mb-3">
        <span class="text-dark-darken font-weight-bold">{{
          $t(`orderEditModals.infoDetails.identification`) | capitalize
        }}</span>
        <span class="text-dark-darken">{{ item.identification || 'N/A' }}</span>
      </div>

      <div class="d-flex flex-column mb-3">
        <span class="text-dark-darken font-weight-bold">{{
          $t(`orderEditModals.infoDetails.volume`) | capitalize
        }}</span>
        <span class="text-dark-darken">{{ item.volume ? `${item.volume}m3` : 'N/A' }}</span>
      </div>

      <div class="d-flex flex-column">
        <span class="text-dark-darken font-weight-bold">{{
          $t(`orderEditModals.infoDetails.weight`) | capitalize
        }}</span>
        <span class="text-dark-darken">{{ item.weight ? `${item.weight}kg` : 'N/A' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'accordion-volumes',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.accordion-volumes {
  .valume-item + .valume-item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
  }

  span {
    font-size: 13px;
  }
}
</style>
